import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { DecommissionedListCategories, DecommissionedListSort, PAGINATION, SortDirection } from "../boot/constants";
import { ReportInfoModel } from "./in-progress-list.store";
import { IObservableArray } from "mobx";
import { BaseFilter } from "../services/filters/base.filter";
import { TApiResponse } from "../types";
import { decommissionedDevicesApi } from "../services/api";
import { IDecommissionedCountResponse } from "../models/IDecommissionedCountResponse";
import { ExportUtils } from "../services/utils";

const getCategoriesTotals = () => {
  const obj: any = {};
  DecommissionedListCategories.forEach(el => {
    const keyName = `${el.state}CategoryTotal`;
    obj[keyName] = types.optional(types.number, 0);
  });

  return obj;
} ;

const categoriesTotals = getCategoriesTotals();


const DecommissionedModel = types
  .model("DecommissionedModel", {
    id: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    decommissionedAt: types.maybeNull(types.string),
    newSerialNumber: types.maybeNull(types.string),
    comments: types.maybeNull(types.string),
    failureMode: types.maybeNull(types.string),
    report: types.maybeNull(ReportInfoModel)
  });

export interface IDecommissionedModel extends Instance<typeof DecommissionedModel> {}
export interface IDecommissionedModelSnapShot extends SnapshotIn<typeof DecommissionedModel> {}

export const DecommissionedListModel = types
  .model("DecommissionedListModel", {
    activeCategory: types.optional(types.string, DecommissionedListCategories[0].value),
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, DecommissionedListSort.serialNumber),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(DecommissionedModel), []),
    categoriesTotals: types.optional(types.model({...categoriesTotals}), {}),
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IDecommissionedModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = DecommissionedListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = DecommissionedListSort.serialNumber;
      self.sortDirection = SortDirection.DESC;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        const result: TApiResponse<IDecommissionedModelSnapShot[]>
          = yield decommissionedDevicesApi.getReleasedList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilter();
        filter.search = self.searchValue || "";

        const result: TApiResponse<IDecommissionedCountResponse> = yield decommissionedDevicesApi.getCounters(filter);
        if (result.isOk) {
          
          DecommissionedListCategories
            .forEach(el => {
              const keyName = `${el.state}CategoryTotal`;
              self.categoriesTotals[keyName] 
                = Object.prototype.hasOwnProperty.call(result.data, el.state) 
                  ? result.data[el.state as keyof IDecommissionedCountResponse]
                  : 0;
            });

          DecommissionedListCategories.forEach((category: any) => {
            if(self.activeCategory === category.value) {
              const keyName = `${category.state}CategoryTotal`;
              self.total = self.categoriesTotals[keyName];
            }
          }); 

          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new BaseFilter();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      try {
        const exportResult = yield decommissionedDevicesApi.getExport(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `Decommissioned_${self.activeCategory || "all"}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      setDefaultParams,
      downloadExport
    };
  });