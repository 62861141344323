import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { ETReleasedDispositionWrapper } from "./et-released-disposition.presents";
import { VQBlueButton, VQDynamicFormComponent, VQGreenButton, VQIcon, VQRadio } from "vivaquant-components-library";
import CloseIcon from "../../assets/icons/close.svg";
import _uniqueId from "lodash.uniqueid";
import { ReleasedDeviceDisposition, ReleasedDeviceDispositionLabels } from "../../boot/enums/ReleasedDeviceDisposition";
import { useRootStore } from "../../stores/storeContext";
import { WIFormService } from "../../services/wi-form.service";
import { confirmAlert } from "react-confirm-alert";
import { message } from "antd";
import { ReleasedDeviceActionType } from "../../boot/enums/ReleasedDeviceActionType";

export const ETReleasedDisposition: FC = observer(() => {
  const {
    comment,
    validateField,
    saveForm,
    closeForm,
    isFormChanged,
    setDeviceDisposition,
    deviceDisposition,
    failureMode,
    serialNumber
  } = useRootStore().releasedDispositionStore;

  const { getTotalCount } = useRootStore().releasedListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []); 
  const alertUser = (e: any) => {
    if (isFormChanged()) {
      e.preventDefault();
    }
  };

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = () => {};

  const validateForm = (action: number) => {
    const { errors, isValid } = validateField(action);
    setErrors(errors);
    return isValid;
  };

  const hide = () => {
    getTotalCount();
    setFormInvalid(false);
    setErrors({});
    setOpenedRightForm(false);
    closeForm();
  };

  const onClose = () => {
    WIFormService.onClose(isFormChanged, confirmAlert, hide);
  };

  const save = (action: number) => {
    setGlobalSpiner(true);
    return saveForm(action)
      .then((res: any) => {
        setGlobalSpiner(false);
        if (res.isOk) {
          const key = "updatable";
          message.success({ content: "Your form has been saved successfully!", key, duration: 3 });
          hide();
        } else {
          const key = "updatable";
          message.error({
            content: res.error?.detail || res.error?.title || "Error, Something went wrong!",
            key,
            duration: 3
          });
        }
      })
      .catch((error: any) => {
        setGlobalSpiner(false);
        const key = "updatable";
        message.error({
          content: error?.detail || error?.title || "Error, Something went wrong!",
          key,
          duration: 3
        });
      });
  };

  const onSubmit = (action: number) => {
    if (!validateForm(action)) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      if (action === ReleasedDeviceActionType.Submit) {
        return confirmAlert({
          message: `Are you sure you want to disposition ${serialNumber} to the 'NCM' stage?`,
          overlayClassName: "sl-confirm-modal",
          buttons: [
            {
              label: "No",
              onClick: () => {}
            },
            {
              label: "Yes",
              className: "confirm-btn",
              onClick: () => { save(action); }
            }
          ]
        });
      } else {
        save(action);
      }
      
    }
  };
  
  return (
    <ETReleasedDispositionWrapper>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={() => {}}>
        <div className="fields-container">
          <div className="col-container">
            {!failureMode.isLoading && <VQDynamicFormComponent
              item={failureMode}
              customClass="col-container-el"
              errors={errors}
              handleChange={handleChange}
              showError={true}
              isFormInvalid={formInvalid}></VQDynamicFormComponent>}
            <div className="col-container-el"></div>
          </div>
          <VQDynamicFormComponent
            item={comment}
            errors={errors}
            handleChange={handleChange}
            showError={true}
            isFormInvalid={formInvalid}></VQDynamicFormComponent>
          <div>
            <div className="col-container">
              <div className="col-container-el label-input">Move to</div>
            </div>
            <div className="col-container">
              <div className="col-container-el flex-inherit">
                <VQRadio 
                  handleChange={() => { setDeviceDisposition(ReleasedDeviceDisposition.Ncm); }}
                  isChecked={deviceDisposition === ReleasedDeviceDisposition.Ncm}
                  label={ReleasedDeviceDispositionLabels.Ncm}
                  name="deviceDisposition"
                  id={_uniqueId()}
                ></VQRadio>
              </div>
            </div> 
          </div>
        </div>
        <div className="btn-container">
          <VQBlueButton
            click={() => {onSubmit(ReleasedDeviceActionType.None);}}
            text="Save"
            type="button"
            width="184px"></VQBlueButton>
          <VQGreenButton
            click={() => {onSubmit(ReleasedDeviceActionType.Submit);}}
            text="Submit"
            type="button"
            width="184px"></VQGreenButton>
        </div>
      </form>
    </ETReleasedDispositionWrapper>
  );
});