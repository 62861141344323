import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { IWI068Response } from "../models/IWI068Response";
import { TApiResponse } from "../types";
import { devicesApi } from "../services/api";
import { IWI068Request } from "../models/IWI068Request";
import { WI068FormState } from "../states/wi068-form.state";
import { WI068ReprogrammingJustificationType } from "../boot/enums/WI068ReprogrammingJustificationType";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { ValidationUtils } from "../services/utils";
import { IWI068CheckPreviousDeviceSerialNumber } from "../models/IWI068CheckPreviousDeviceSerialNumber";

export const WI068FormModel = types
  .model("WI068FormModel", {
    show: types.optional(types.boolean, false),
    baseData: types.frozen(),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    stage: types.maybeNull(types.number),
    wiRev: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    previousDeviceSerialNumber: types.maybe(InputFormModel),
    previousDeviceIsEditable: types.optional(types.boolean, true),
    ncmOrFaNumber: types.maybe(InputFormModel),
    reprogrammingJustificationType: types.maybeNull(types.number),
    reprogrammingJustificationTypeErr: types.maybeNull(types.string),
    failureMode: types.maybe(InputFormModel),
    isNextDevice: types.optional(types.boolean, false)

  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.reprogrammingJustificationType = WI068ReprogrammingJustificationType.None;
      self.reprogrammingJustificationTypeErr = "";
      self.baseData = {};
      self.isNextDevice = false;
      self.previousDeviceIsEditable = true;
      applySnapshot(self, WI068FormState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const setReprogrammingJustificationType = (value: number) => {
      if (self.reprogrammingJustificationType === value) {
        self.reprogrammingJustificationType = WI068ReprogrammingJustificationType.None;
      } else {
        self.reprogrammingJustificationType = value;
      } 
    };

    const validateField = flow(function* validateField(action: number, isHistorical?: boolean) {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }

        if (self.previousDeviceIsEditable && !self.previousDeviceSerialNumber?.value) {
          errors.previousDeviceSerialNumber = "Please fill the field";
          isValid = false;
        }

        if (!self.ncmOrFaNumber?.value) {
          errors.ncmOrFaNumber = "Please fill the field";
          isValid = false;
        }

        if (self.reprogrammingJustificationType === WI068ReprogrammingJustificationType.None) {
          self.reprogrammingJustificationTypeErr = "Please fill the field";
          isValid = false;
        } else {
          self.reprogrammingJustificationTypeErr = "";
        }
      }

      if (action === WorkInstructionActionType.Fail) {
        if (self.previousDeviceIsEditable && !self.previousDeviceSerialNumber?.value) {
          errors.previousDeviceSerialNumber = "Please fill the field";
          isValid = false;
        }
      }

      if (self.previousDeviceIsEditable && self.previousDeviceSerialNumber?.value 
        && !ValidationUtils.isSerialNum(self.previousDeviceSerialNumber?.value)
      ) {
        errors.previousDeviceSerialNumber = "Previous device serial number must be of RX000000 format";
        isValid = false;
      }

      if (action === WorkInstructionActionType.Pass || action === WorkInstructionActionType.Fail) {
        if (self.previousDeviceIsEditable && self.previousDeviceSerialNumber?.value) {
          if (!ValidationUtils.isSerialNum(self.previousDeviceSerialNumber?.value)) {
            errors.previousDeviceSerialNumber = "Previous device serial number must be of RX000000 format";
            isValid = false;
          } else {
            const deviceInfo 
              = yield checkPreviousDeviceSerialNumber(self.previousDeviceSerialNumber?.value, isHistorical);
            if (deviceInfo) {
              if (!deviceInfo.isExist) {
                errors.previousDeviceSerialNumber = "The entered serial number does not exist in the system";
                isValid = false;
              } else if (deviceInfo.device?.isLocked) {
                errors.previousDeviceSerialNumber 
                  = `The entered serial number is already locked to device ${deviceInfo.device?.lockedBySerialNumber}`;
                isValid = false;
              } else if (!deviceInfo.device?.isDecommissioned) {
                errors.previousDeviceSerialNumber 
                  = "The entered serial number belongs to a device that is not decommissioned";
                isValid = false;
              }
            }
          }
        }
      }

      return {
        errors,
        isValid
      };
    });

    const checkPreviousDeviceSerialNumber 
    = flow(function* checkPreviousDeviceSerialNumber(value: string, isHistorical?: boolean){
      try {
        const response: TApiResponse<IWI068CheckPreviousDeviceSerialNumber> 
        = yield devicesApi.getWI068CheckPreviousDeviceSerialNumber(self.deviceId, value, isHistorical);
        if (response.isOk) {
          return response.data;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI068Response> = yield devicesApi.getWI068(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.reprogrammingJustificationType = data.reprogrammingJustificationType;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );

          if (data.previousDevice === null) {
            self.previousDeviceSerialNumber.setValue("");
            self.previousDeviceIsEditable = true;
          } else {
            self.previousDeviceSerialNumber.setValue(data.previousDevice?.serialNumber || "");
            self.previousDeviceIsEditable = !!data.previousDevice?.isEditable;
          }
          self.ncmOrFaNumber.setValue(
            data.ncmOrFaNumber ? data.ncmOrFaNumber 
              : isTransferData ? self.ncmOrFaNumber.value : ""
          );
          
          self.comment.setValue(data.comment || "");
          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);


          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }
          
          self.baseData = {
            wiRev: data.wiRev,
            previousDeviceSerialNumber: data.previousDevice.serialNumber,
            ncmOrFaNumber: data.ncmOrFaNumber,
            comment: data.comment,
            failureMode: data.failureMode,
            reprogrammingJustificationType: data.reprogrammingJustificationType
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI068Request = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          comment: self.comment.value,
          previousDeviceSerialNumber: self.previousDeviceSerialNumber.value,
          ncmOrFaNumber: self.ncmOrFaNumber.value,
          reprogrammingJustificationType: self.reprogrammingJustificationType,
          failureMode: +self.failureMode.value,
          action: action
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI068(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.previousDeviceSerialNumber.value !== self.baseData.previousDeviceSerialNumber
        || self.ncmOrFaNumber.value !== self.baseData.ncmOrFaNumber
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
        || self.reprogrammingJustificationType !== self.baseData.reprogrammingJustificationType
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setReprogrammingJustificationType,
      setIsNextDevice
    };
    
  });

export interface IWI068FormModelSnapShot extends SnapshotIn<typeof WI068FormModel> {}
