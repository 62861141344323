export enum DeviceStatus {
  InProgress = 0,
  MovedToNcm = 1,
  Released = 2,
  Decommissioned = 3
}

export enum DeviceStatusLabel {
  InProgress = "In Progess",
  MovedToNcm = "NCM",
  Released = "Released",
  Decommissioned = "Decommissioned"
}