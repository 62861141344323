import { ReleasedDeviceFailureMode, ReleasedDeviceFailureModeLabels } from "../boot/enums/ReleasedDeviceFailureMode";
import { IReleasedDispositionModelSnapShot } from "../stores/released-disposition.store";

export const ReleasedDispositionState: IReleasedDispositionModelSnapShot = {
  failureMode: {
    name: "Failure Mode",
    value: ReleasedDeviceFailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(ReleasedDeviceFailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: ReleasedDeviceFailureMode[key as keyof typeof ReleasedDeviceFailureMode].toString(),
          label: ReleasedDeviceFailureModeLabels[key as keyof typeof ReleasedDeviceFailureMode],
        };
      }),
    defaultValue: { value: ReleasedDeviceFailureMode.None.toString(), label: ReleasedDeviceFailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
