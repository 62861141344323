import { IUserProfile } from "../models/IUserProfile";

export enum Permissions {
  Login = 1 << 0,
  UserInvite = 1 << 1,
  DevicesReadAll = 1 << 2,
  DevicesReadOwned = 1 << 3,
  DevicesExport = 1 << 4,
  DevicesImport = 1 << 5,
  DevicesEdit = 1 << 6,
  DevicesEditOwned = 1 << 7,
  RouteConfigurationRead = 1 << 8,
  RouteConfigurationEdit = 1 << 9,
  RouteConfigurationDelete = 1 << 10,
  DeviceWIRead = 1 << 11,
  DeviceWIEdit = 1 << 12,
  DeviceWIPassFail = 1 << 13,
  DeviceWI119PassFail = 1 << 14,
  BatchRead = 1 << 15,
  BatchEdit = 1 << 16,
  DeviceWI59ERPassFail = 1 << 17,
  DeviceWI59EREditDispositionOfDevice = 1 << 18,
  DeviceWI48PassFail = 1 << 19,
  DeviceWI49PassFail = 1 << 20,
  NcmDevicesRead = 1 << 21,
  NcmDevicesEdit = 1 << 22,
  ReportRead = 1 << 23,
  DecommissionedDevicesRead = 1 << 24,
  DecommissionedDevicesEdit = 1 << 25,
  ReleasedDevicesRead = 1 << 26,
  ReleasedDevicesEdit = 1 << 27,
  All = ~0
}

export abstract class PermissionService {
  public static hasPermission(user: IUserProfile, perm: Permissions): boolean {
    const n = user.permissions;
    return (n & perm) !== 0;
  }
}
