import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQDynamicFormComponent } from "vivaquant-components-library";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI041Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    boardReversion,
    dimationBoardSN,
    imei,
    iccid,
    failureMode,
    comment
  } = useRootStore().wi041FormStore;

  const handleChange = () => {};
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9]/g, ""));
  };

  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={boardReversion}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={dimationBoardSN}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={imei}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={iccid}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});
