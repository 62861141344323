import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../stores/storeContext";
import { Permissions } from "../../services/permission.service";
import { confirmAlert } from "react-confirm-alert";
import { DateUtils, StringsUtils } from "../../services/utils";
import { VQIcon, VQIconSort, VQNoResult, VQTableLoader, VQTableSection, VQTooltip } from "vivaquant-components-library";
import SortListIcon from "../../assets/icons/sort-list.svg";
import ReporteIcon from "../../assets/icons/report.svg";
import { DecommissionedListSort, PAGINATION, SortDirection } from "../../boot/constants";
import { IDecommissionedModel } from "../../stores/decommissioned-list.store";
import { ETComment } from "../et-comment";
import _uniqueId from "lodash.uniqueid";

export const ETDecommissionedListTable: FC = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
  } = useRootStore().decommissionedListStore;
  const { setGlobalSpiner, isOpenedRightForm } = useRootStore().globalStore;
  const { openModal, getReport } = useRootStore().reportStore;

  const { hasPermission } = useRootStore().userStore;
  const isReportRead = hasPermission(Permissions.ReportRead);

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const onReport = (id: string, serialNumber: string, date: string) => {
    setGlobalSpiner(true);
    return getReport(id, StringsUtils.reportName(serialNumber, date))
      .then((msg: string) => {
        if (msg) {
          confirmAlert({
            message: msg,
            overlayClassName: "sl-warning-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          openModal();
        }
        
        setGlobalSpiner(false);
      });
  };

  const getContent = () => {
    if (isLoading) {    
      return (
        <VQTableLoader
          columns={6}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: IDecommissionedModel, index: number) => {
          return (
            <tr key={index}>
              <td>
                <div className="td-item">
                  {item.serialNumber || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.decommissionedAt ? DateUtils.formatDate(item.decommissionedAt) : "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.newSerialNumber || "N/A"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.failureMode || "-"}
                </div>
              </td>
              <td style={{width: "500px"}}>
                <ETComment comment={item.comments}></ETComment>
              </td>
              <td className="action">
                <div className="td-item">
                  <div className="td-item actions-wrappwer">
                    <div
                      className={`action-item ${!isOpenedRightForm && isReportRead && item.report ? "" : "disabled"}`}
                      onClick={() => { onReport(item.report.id, item.serialNumber, item.report.updatedAt); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Open Device Report</div>}>
                        <VQIcon 
                          icon={ReporteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                  </div>
                </div>
              </td>
              
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="margin-table scroll-table">
      <VQTableSection>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(DecommissionedListSort.serialNumber, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(DecommissionedListSort.serialNumber, SortDirection.DESC)} 
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Serial Number</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(DecommissionedListSort.movedToDecommissionedAt, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(DecommissionedListSort.movedToDecommissionedAt, SortDirection.DESC)} 
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Decommissioned Date</div>
              </div>
            </th>
            <th>New Serial Number</th>
            <th>Failure Mode</th>
            <th>Comment</th>
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
      </VQTableSection>
      {items.length === 0 && !isLoading && <VQNoResult/> }
    </div>
  );
});
