import { SnapshotIn, applySnapshot, cast, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { AdminDevicesEditState } from "../states/admin-devices-edit.state";
import { StringsUtils, ValidationUtils } from "../services/utils";
import { TApiResponse } from "../types";
import { batchesApi, devicesApi } from "../services/api";
import { IEditDeviceResponse } from "../models/IEditDeviceResponse";
import { IEditDeviceRequest } from "../models/IEditDeviceRequest";
import { BaseFilter } from "../services/filters/base.filter";
import { SortDirection } from "../boot/constants";
import { IBatche } from "../models/IBatche";
import { WorkInstructionType } from "../boot/enums/WorkInstructionType";

export const AdminDevicesEditModel = types
  .model("AdminDevicesEditModel", {
    show: types.optional(types.boolean, false),
    isSubmited: types.optional(types.boolean, false),
    id: types.maybeNull(types.string),
    batchNumber: types.maybe(InputFormModel),
    serialNumber: types.maybeNull(types.string),
    routeConfiguration: types.maybe(InputFormModel),
    workInstruction: types.maybe(InputFormModel),
    baseData: types.frozen(),
    batchNumbersList: types.optional(types.array(types.string), []),
    isWIFormOpened: types.optional(types.boolean, false),
    isSelectedWIEditable: types.optional(types.boolean, false),
    selectedWIOldValue: types.optional(types.string, ""),
    baseWorkInstruction: types.optional(types.frozen(), {}),
    isLoading: types.optional(types.boolean, false),
    isRevertAvailable: types.optional(types.boolean, false),
    isRevertHasAnyCompeletedState: types.optional(types.boolean, false),
    isRevertReverted: types.optional(types.boolean, false),
    isRevertToStatus: types.maybeNull(types.number),
    isRevertToWorkInstructionType: types.maybeNull(types.number)
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const resetForm = () => {
      self.id = null;
      self.isSubmited = false;
      applySnapshot(self, AdminDevicesEditState);
      self.serialNumber = null;
      self.baseData = {};
      self.batchNumbersList = cast([]);
      self.isWIFormOpened = false;
      self.isSelectedWIEditable = false;
      self.selectedWIOldValue = "";
      self.baseWorkInstruction = {};
      self.isLoading = false;
      self.isRevertAvailable = false;
      self.isRevertReverted = false;
      self.isRevertHasAnyCompeletedState = false;
      self.isRevertToStatus = null;
      self.isRevertToWorkInstructionType = null;
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.batchNumber?.value) {
        errors.batchNumber = "Please fill the field";
        isValid = false;
      } else if (!ValidationUtils.isBatchID(self.batchNumber?.value)) {
        errors.batchNumber = "Batch ID must be of B00000 format";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(id: string) {
      try {
        const response: TApiResponse<IEditDeviceResponse> = yield devicesApi.getDevice(id);
        if (response.isOk) {
          const { data } = response;
          self.id = data.id;
          self.isRevertAvailable = data.reversion.isAvailable;
          self.isRevertReverted = data.reversion.isReverted;
          self.isRevertHasAnyCompeletedState = data.reversion.hasAnyCompeletedState;
          self.isRevertToStatus = data.reversion.toStatus;
          self.isRevertToWorkInstructionType = data.reversion.toWorkInstructionType;
          self.batchNumber.setValue(data.batchNumber || "");
          self.serialNumber = data.serialNumber;
          self.routeConfiguration.setValue(data.routeConfiguration.name || "");

          const options = data.workInstructions.map((item) => {
            const subLabel = [];
            if (!item.isEditable) {
              subLabel.push("Non Editable");
            }
            if (item.isCurrentStage) {
              subLabel.push("Current Stage");
            }
            return {
              value: item.type.toString(),
              label: StringsUtils.addWIDashes(WorkInstructionType[item.type]),
              subLabel: subLabel.join(", "),
              isEditable: item.isEditable
            };
          });

          self.workInstruction.setOptions(options);
          self.baseWorkInstruction = options;

          self.baseData = {
            batchNumber: data.batchNumber,
          };
          getBatchNumbersList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getBatchNumbersList = flow(function* getBatchNumbersList() {
      const filter = new BaseFilter();
      filter.skip = 0;
      filter.take = 5;
      filter.search = self.batchNumber.value || "";
      filter.sortBy = "batchid";
      filter.sortDirection = SortDirection.ASC;

      const response: TApiResponse<IBatche[]> = yield batchesApi.getBatchesList(filter);
      if (!response.isOk || !response.data) {
        return;
      }
      self.batchNumbersList = cast(response.data.map((item: IBatche) => {
        return item.batchId;
      }));
    });

    const setBatchNumber = (value: string) => {
      self.batchNumber.setValue(value);
    };

    const isFormChanged = (isSubFormChanged?: Function) => {
      if (isSubFormChanged && isSubFormChanged()) {
        return true;
      } else if (self.baseData && self.batchNumber?.value !== self.baseData?.batchNumber ) {
        return true;
      } else {
        return false;
      }
    };

    const saveForm = flow(function* saveForm() {
      self.isSubmited = true;
      try {
        const data: IEditDeviceRequest = {
          batchNumber: self.batchNumber.value,
          deviceIds: [self.id]
        };

        const result = yield devicesApi.saveDevices(data);
        
        self.baseData = {
          batchNumber: self.batchNumber.value,
        };

        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const revertState = flow(function* revertState() {
      try {
        const result = yield devicesApi.revertState(self.id);
        if (result.isOk) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const setIsWIFormOpened = (value: boolean) => {
      self.isWIFormOpened = value;
    };

    const setSelectedWIEditable = (value: boolean) => {
      self.isSelectedWIEditable = value;
    };
    const setSelectedWIOldValue = (value: string) => {
      self.selectedWIOldValue = value;
    };

    return {
      openForm,
      closeForm,
      saveForm,
      validateField,
      resetForm,
      fetchData,
      isFormChanged,
      getBatchNumbersList,
      setBatchNumber,
      setIsWIFormOpened,
      setSelectedWIEditable,
      setSelectedWIOldValue,
      revertState
    };
  });

export interface IAdminDevicesEditModelSnapShot extends SnapshotIn<typeof AdminDevicesEditModel> {}
