import { FC, useState } from "react";
import { theme } from "../../theme/theme";
import { observer } from "mobx-react-lite";

export const ETComment: FC<{comment: string}> = observer((props: {comment: string}) => {
  const size = 40;
  const [showMore, setShowMore] = useState(false);

  if (!props.comment) {
    return (
      <div className="td-item">{"-"}</div>
    );
  }

  if (props.comment.length > size) {
    if (!showMore) {
      return (
        <div className="td-item" style={{whiteSpace: "pre-line"}}>
          {`${props.comment.substring(0, size)}...   `}
          <span 
            style={{cursor: "pointer", color: theme.colors.blue}}
            onClick={() => {setShowMore(true);}}>
              Read Full Comment
          </span>
        </div>
      );
    } else {
      return (
        <div className="td-item" style={{whiteSpace: "pre-line"}}>
          {props.comment}
          <div 
            style={{cursor: "pointer", color: theme.colors.blue, marginTop: "8px"}}
            onClick={() => {setShowMore(false);}}>
              Hide Full Comment
          </div>
        </div>
      );
    }
    
  } 

  return (
    <div className="td-item" style={{whiteSpace: "pre-line"}}>{props.comment}</div>
  );
});