import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETAdminDevicesEditWrapper = styled.div`
  height: calc(100% - 96px);

  form {
    height: 100%;
  }

  .form-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: ${theme.colors.text};
    margin-top: 18px;
    padding-top: 16px;
    padding-bottom: 6px;
    border-top: 1px solid ${theme.colors.background};

    .form-info {
      display: flex;
      align-items: center;
      gap: 4px;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .revert-container {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  
  .help-container {
    position: relative;

    .help {
      position: absolute;
      background: ${theme.colors.light};
      z-index: 1;
      left: 0;
      right: 0;
      border-radius: 10px;
      border: 1px solid ${theme.colors.background};
      margin: 0;
      padding: 16px;
      margin-top: -10px;

      .help-item {
        font-size: 14px;
        line-height: 16px;
        padding: 16px 0;
        border-top: 1px solid ${theme.colors.background};
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.text};
        cursor: pointer;
      }
      
      .help-item:first-child {
        border-top: none;
      }
    }
  }

  .opttion-container {
    padding-top: 20px;
    padding-bottom: 15px;
    border-top: 1px solid ${theme.colors.greyDivider};
    position: relative;

    .label {
      color: ${theme.colors.label};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      margin-bottom: 16px;
    }
  }

  .opttion-container:last-child {
    border-bottom: 1px solid ${theme.colors.greyDivider};
  }
`;