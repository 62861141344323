import { observer } from "mobx-react-lite";
import { FC, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRootStore } from "../../../stores/storeContext";
import { VQAuthLogin } from "vivaquant-components-library";
import { ETHeaderLogoOnly } from "../../../components/et-header";
import { RECAPTCHA_KEY } from "../../../boot/constants";
import { StringsUtils } from "../../../services/utils";

export const LoginPage: FC = observer(() => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [message, setMessage] = useState<{message: string, isError: boolean}>(locationState as never);

  const { login, loginTwoFactor, resendLoginCode } = useRootStore().userStore;

  const {
    email,
    password,
    invalidEmail,
    invalidPassword,
    // tokenReCaptcha,
    isSubmit,
    isShowPassword,
    isErrorLogin,
    isLoading,
    setEmail,
    setPassword,
    setSubmit,
    setShowPassword,
    setErrorLogin,
    setLoading,
    setTokenReCaptcha,
    cleanData
  } = useRootStore().loginFormStore;

  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [resentCode, setResentCode] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const { setDefaultParams: setDefaultParamsInProgress } = useRootStore().inProgressListStore;
  const { setDefaultParams: setDefaultParamsReleased } = useRootStore().releasedListStore;
  const { setDefaultParams: setDefaultParamsNSM } = useRootStore().ncmListStore;
  const { setDefaultParams: setDefaultParamsDecommissioned } = useRootStore().decommissionedListStore;
  const { setDefaultParams: setDefaultParamsAdminDevices } = useRootStore().adminDevicesListStore;
  const { setDefaultParams: setDefaultParamsAdminRouteConfiguration } = useRootStore().adminRouteConfigurationListStore;

  const cleanPagination = () => {
    setDefaultParamsInProgress();
    setDefaultParamsReleased();
    setDefaultParamsNSM();
    setDefaultParamsDecommissioned();
    setDefaultParamsAdminDevices();
    setDefaultParamsAdminRouteConfiguration();
  };

  const onSubmit = async(event: FormEvent) => {
    event.preventDefault();
    setSubmit();
    // [Temp]: Disabled recapcha validation
    // if (invalidEmail || invalidPassword || !tokenReCaptcha || !email || !password) { return; }
    if (invalidEmail || invalidPassword || !email || !password) { return; }
    setLoading(true);
    const result = await login(email, password);
    if (result) {
      if (typeof result === "object" && result.email) {
        setIsTwoFactor(true);
        setTelNumber(result.email);
      } else {
        setIsTwoFactor(false);
        setTelNumber("");
        cleanData();
        cleanPagination();
        navigate("/in-progress");
      }
    } else {
      setErrorLogin(true);
    }
    setLoading(false);
  };

  const onValidateCode = async(event: any) => {
    event.preventDefault();
    setLoading(true);
    const result = await loginTwoFactor(email, authCode);
    if (result) {
      cleanData();
      navigate("/in-progress");
    } else {
      setErrorCode(true);
    }
    setLoading(false);
  };

  const forgotPass = () => {
    navigate("/forgot-password");
  };

  const showPassword = () => {
    setShowPassword();
  };

  const handleVerifyRecaptcha = (token: string) => {
    setTokenReCaptcha(token || "");
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = StringsUtils.hideEmail(email || "");
    const sentText = resentCode ? "resent" : "sent";
    return `A message with a verification code has been ${sentText} to your email ${replaced}.
    Enter the code to continue.`;
  };

  const handleOnChangeAuthCode = (res: string) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const retrySendCode = () => {
    setResentCode(true);
    resendLoginCode(email);
  };

  useEffect(() => {
    if (locationState) {
      setMessage(locationState as never);
      navigate("", { replace: true });
    }
  }, []);

  return (
    <VQAuthLogin
      header={(<ETHeaderLogoOnly></ETHeaderLogoOnly>)}
      isTwoFactor={isTwoFactor}
      telNumber={telNumber}
      authCode={authCode}
      errorCode={errorCode}

      setEmail={setEmail}
      setPassword={setPassword}

      isLoading={isLoading}
      isSubmit={isSubmit}
      invalidEmail={invalidEmail}
      invalidPassword={invalidPassword}
      isShowPassword={isShowPassword}
      message={message && message.message ? message.message : ""}
      isErrorLogin={isErrorLogin}
      onSubmit={onSubmit}
      email={email}
      password={password}
      recaptchaKey={RECAPTCHA_KEY}

      showPassword={showPassword}
      forgotPass={forgotPass}
      handleVerifyRecaptcha={handleVerifyRecaptcha}
      getTwoFactorAuthenticationMessage={getTwoFactorAuthenticationMessage}
      handleOnChangeAuthCode={handleOnChangeAuthCode}
      onValidateCode={onValidateCode}
      retrySendCode={retrySendCode}
    ></VQAuthLogin>
  );
});
