import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { WI048FormState } from "../states/wi048-form.state";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { TApiResponse } from "../types";
import { IWI048Response } from "../models/IWI048Response";
import { devicesApi } from "../services/api";
import { IWI048Request } from "../models/IWI048Request";

export const WI048FormModel = types
  .model("WI048FormModel", {
    show: types.optional(types.boolean, false),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    wiRev: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    stage: types.maybeNull(types.number),
    baseData: types.frozen(),
    failureMode: types.maybe(InputFormModel),
    dmm: types.maybe(InputFormModel),
    ecgSimulator: types.maybe(InputFormModel),
    testFixture: types.maybe(InputFormModel),
    wiFiSignalRssi: types.maybe(InputFormModel),
    wiFiCurrent: types.maybe(InputFormModel),
    state1Current: types.maybe(InputFormModel),
    state4Current: types.maybe(InputFormModel),
    modemCurrent: types.maybe(InputFormModel),
    isNextDevice: types.optional(types.boolean, false)
  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.baseData = {};
      self.isNextDevice = false;
      applySnapshot(self, WI048FormState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }

        if (!self.dmm?.value) {
          errors.dmm = "Please fill the field";
          isValid = false;
        }

        if (!self.ecgSimulator?.value) {
          errors.ecgSimulator = "Please fill the field";
          isValid = false;
        }

        if (!self.testFixture?.value) {
          errors.testFixture = "Please fill the field";
          isValid = false;
        }

        if(!self.wiFiSignalRssi?.value) {
          errors.wiFiSignalRssi = "Please fill the field";
          isValid = false;
        }

        if(!self.wiFiCurrent?.value) {
          errors.wiFiCurrent = "Please fill the field";
          isValid = false;
        }

        if (!self.state1Current?.value) {
          errors.state1Current = "Please fill the field";
          isValid = false;
        }

        if (!self.state4Current?.value) {
          errors.state4Current = "Please fill the field";
          isValid = false;
        }

        if (!self.modemCurrent?.value) {
          errors.modemCurrent = "Please fill the field";
          isValid = false;
        }
      }

      if (self.wiFiSignalRssi?.value && (+self.wiFiSignalRssi.value < -80 || +self.wiFiSignalRssi.value > -30)) {
        errors.wiFiSignalRssi = "Field should be from -80 to -30";
        isValid = false;
      }

      if (self.wiFiCurrent?.value && (+self.wiFiCurrent.value < -100 || +self.wiFiCurrent.value > 100)) {
        errors.wiFiCurrent = "Field should be from -100 to 100";
        isValid = false;
      }

      if (self.state1Current?.value && +self.state1Current.value >= 0.0004) {
        errors.state1Current = "Field should be less than 0.0004";
        isValid = false;
      } else if (self.state1Current?.value && +self.state1Current.value <= -0.0004) {
        errors.state1Current = "Field should be more than -0.0004";
        isValid = false;
      }

      if (self.state4Current?.value && +self.state4Current.value >= 0.0020) {
        errors.state4Current = "Field should be less than 0.0020";
        isValid = false;
      } else if (self.state4Current?.value && +self.state4Current.value <= -0.0020) {
        errors.state4Current = "Field should be more than -0.0020";
        isValid = false;
      }

      if (self.modemCurrent?.value && (+self.modemCurrent.value < -250 || +self.modemCurrent.value > 250)) {
        errors.modemCurrent = "Field should be from -250 to 250";
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI048Response> = yield devicesApi.getWI048(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );
          self.dmm.setValue(
            data.dmm ? data.dmm 
              : isTransferData ? self.dmm.value : ""
          );
          self.ecgSimulator.setValue(
            data.ecgSimulator ? data.ecgSimulator 
              : isTransferData ? self.ecgSimulator.value : ""
          );
          self.testFixture.setValue(
            data.testFixture ? data.testFixture 
              : isTransferData ? self.testFixture.value : ""
          );
          self.wiFiSignalRssi.setValue(
            data.wiFiSignalRssi || data.wiFiSignalRssi === 0 ? data.wiFiSignalRssi.toString()
              : isTransferData ? self.wiFiSignalRssi.value : ""
          );
          self.wiFiCurrent.setValue(
            data.wiFiCurrent || data.wiFiCurrent === 0 ? data.wiFiCurrent.toString() 
              : isTransferData ? self.wiFiCurrent.value : ""
          );
          self.state1Current.setValue(
            data.state1Current || data.state1Current === 0 ? data.state1Current.toString() 
              : isTransferData ? self.state1Current.value : ""
          );
          self.state4Current.setValue(
            data.state4Current || data.state4Current === 0 ? data.state4Current.toString() 
              : isTransferData ? self.state4Current.value : ""
          );
          self.modemCurrent.setValue(
            data.modemCurrent || data.modemCurrent === 0 ? data.modemCurrent.toString() 
              : isTransferData ? self.modemCurrent.value : ""
          );
          
          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);
          
          self.comment.setValue(data.comment || "");

          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }

          self.baseData = {
            wiRev: data.wiRev,
            dmm: data.dmm,
            ecgSimulator: data.ecgSimulator,
            testFixture: data.testFixture,
            wiFiSignalRssi: data.wiFiSignalRssi || data.wiFiSignalRssi === 0 ? data.wiFiSignalRssi.toString() : "",
            wiFiCurrent: data.wiFiCurrent || data.wiFiCurrent === 0 ? data.wiFiCurrent.toString() : "",
            state1Current: data.state1Current || data.state1Current === 0 ? data.state1Current.toString() : "",
            state4Current: data.state4Current || data.state4Current === 0 ? data.state4Current.toString() : "",
            modemCurrent: data.modemCurrent || data.modemCurrent === 0 ? data.modemCurrent.toString() : "",
            comment: data.comment,
            failureMode: data.failureMode,
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI048Request = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          dmm: self.dmm.value,
          ecgSimulator: self.ecgSimulator.value,
          testFixture: self.testFixture.value,
          wiFiSignalRssi: self.wiFiSignalRssi.value ? +self.wiFiSignalRssi.value : null,
          wiFiCurrent: self.wiFiCurrent.value ? +self.wiFiCurrent.value : null,
          state1Current: self.state1Current.value ? +self.state1Current.value : null,
          state4Current: self.state4Current.value ? +self.state4Current.value : null,
          modemCurrent: self.modemCurrent.value ? +self.modemCurrent.value : null,
          comment: self.comment.value,
          failureMode: +self.failureMode.value,
          action: action
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI048(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.dmm.value !== self.baseData.dmm
        || self.ecgSimulator.value !== self.baseData.ecgSimulator
        || self.testFixture.value !== self.baseData.testFixture
        || self.wiFiSignalRssi.value !== self.baseData.wiFiSignalRssi
        || self.wiFiCurrent.value !== self.baseData.wiFiCurrent
        || self.state1Current.value !== self.baseData.state1Current
        || self.state4Current.value !== self.baseData.state4Current
        || self.modemCurrent.value !== self.baseData.modemCurrent
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setIsNextDevice
    };
  });

export interface IWI048FormModelSnapShot extends SnapshotIn<typeof WI048FormModel> {}
