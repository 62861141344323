import { API } from "../../boot/api";
import { IReleasedCountResponse } from "../../models/IReleasedCountResponse";
import { IReleasedDispositionRequest } from "../../models/IReleasedDispositionRequest";
import { IReleasedDispositionResponse } from "../../models/IReleasedDispositionResponse";
import { ApiResponseDto } from "../../models/api";
import { IReleasedModelSnapShot } from "../../stores/released-list.store";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { ReleasedListFilter } from "../filters/released.filter";

class ReleasedDevicesApi {
  @ProcessAPIError()
  public async getCounters(filter: ReleasedListFilter): Promise<TApiResponse<IReleasedCountResponse>> {
    const result = await API.get("/released-devices/count", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getReleasedList(filter: ReleasedListFilter, value: string)
  : Promise<TApiResponse<IReleasedModelSnapShot[]>> {
    const { data } = await API.get(`/released-devices${value ? "/" + value : "" }`,
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: ReleasedListFilter, value: string): Promise<TApiResponse<any>> {
    const result = await API.get(`/released-devices${value ? "/" + value : "" }/export`,
      { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getFunctionalDemoLocations(filter: ReleasedListFilter)
  : Promise<TApiResponse<string[]>> {
    const { data } = await API.get("/released-devices/locations",
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getReleasedDevice(id: string): Promise<TApiResponse<IReleasedDispositionResponse>> {
    const { data } = await API.get(`/released-devices/${id}`);
    return new ApiResponseDto(data);
  }
  
  @ProcessAPIError()
  public async saveReleasedDevice(data: IReleasedDispositionRequest): Promise<TApiResponse<string>> {
    const result = await API.patch("/released-devices", data);
    return new ApiResponseDto(result.data);
  }

}

export const releasedDevicesApi = new ReleasedDevicesApi();