import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { DecommissionedPageWrapper } from "./Decommissioned.present";
import { ETReportModal } from "../../components/et-report-modal";
import { useRootStore } from "../../stores/storeContext";
import { ETDecommissionedListHeader } from "../../components/et-decommissioned-list-header";
import { ETDecommissionedListTable } from "../../components/et-decommissioned-list-table";

export const DecommissionedPage: FC = observer(() => {
  const { getTotalCount } = useRootStore().decommissionedListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { show: showReport } = useRootStore().reportStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);

  return (
    <>
      <DecommissionedPageWrapper headerHeight={headerHeight}>
        <ETDecommissionedListHeader></ETDecommissionedListHeader>
        <ETDecommissionedListTable></ETDecommissionedListTable>
      </DecommissionedPageWrapper>
      {showReport && <ETReportModal></ETReportModal>}
    </>
  );
});
