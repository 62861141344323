export enum ReleasedDeviceFailureMode {
  None = 0,
  CurrentState1 = 1,
  CurrentState4 = 2,
  CurrentModem = 3,
  CurrentWiFi = 4,
  WiFiConnectionFailure = 5,
  AlarmConstant = 6,
  AlarmNone = 7,
  NoLeadsOn = 8,
  NoLeadsOff = 9,
  Unresponsive = 10,
  ResetLooping = 11,
  McUdateFailureHashSign = 12,
  DsPupdateFailure = 13,
  McUpdateFailureDollarSign = 14,
  VibrationFailureNone = 15,
  VibrationFailureConstant = 16,
  BeatCountWrong = 17,
  SimCardFailure = 18,
  BoardPowerOnFailure = 19,
  CannotCompleteHardReset = 20,
  FailedPost = 21,
  LedFailure = 22,
  VibrationFailure = 23,
  LedFailureAfterHardReset = 24,
  PhysicalDamage = 25,
  DeviceNotReleased = 26,
  PostFailure = 27,
  MissingOrDamagedLabel = 28,
  DefectiveConnector = 29,
  ChargeFailure = 30,
  BoardVoltage = 31,
  ProgrammerConnection = 32,
  MCProgrammingError = 33,
  DSPProgrammingError = 34,
  RedCellLed = 35,
  RedHeartLed = 36,
  NoCommunication = 37,
  PowerOnFailure = 38,
  ResetLoop = 39,
  AlarmsOnCharger = 40,
  EcgSnapFailure = 41,
  CellSignalFailure = 42,
  EcgDataFailure = 43,
  ConnectorFailure = 44,
  SdCardFailure = 45,
  UnrepairableLeak = 46,
  EcgFailure = 47,
  Labeling = 48,
  MCUnlockError = 49,
  DebugInFailure = 50,
  DebugOutFailure = 51,
  UnableToFindBoard = 52,
  ModemUpdateFailure = 53,
  ModemPowerOnFailure = 54,
  ModemPowerOffFailure = 55,
  DocumentationError = 56,
  Other = 57
}

export enum ReleasedDeviceFailureModeLabels {
  None = "None",
  CurrentState1 = "Current State 1",
  CurrentState4 = "Current State 4",
  CurrentModem = "Current Modem",
  CurrentWiFi = "Current Wi-Fi",
  WiFiConnectionFailure = "Wi-Fi Connection Failure",
  AlarmConstant = "Alarm (Constant)",
  AlarmNone = "Alarm (None)",
  NoLeadsOn = "No \"Leads On\"",
  NoLeadsOff = "No \"Leads Off\"",
  Unresponsive = "Unresponsive",
  ResetLooping = "Reset Looping",
  McUdateFailureHashSign = "\"#\" MC update Failure",
  DsPupdateFailure = "\"@\" DSP update Failure",
  McUpdateFailureDollarSign = "\"$\" MC update Failure",
  VibrationFailureNone = "Vibration Failure (None)",
  VibrationFailureConstant = "Vibration Failure (Constant)",
  BeatCountWrong = "Beat Count Wrong",
  SimCardFailure = "SIM Card Failure",
  BoardPowerOnFailure = "Board Power On Failure",
  CannotCompleteHardReset = "Cannot Complete Hard Reset",
  FailedPost = "Failed POST",
  LedFailure = "LED failure",
  VibrationFailure = "Vibration Failure",
  LedFailureAfterHardReset = "LED Failure after hard reset",
  PhysicalDamage = "Physical damage",
  DeviceNotReleased = "Device not released",
  PostFailure = "POST Failure",
  MissingOrDamagedLabel = "Missing/Damaged Label",
  DefectiveConnector = "Defective Connector",
  ChargeFailure = "Charge failure",
  BoardVoltage = "Board Voltage",
  ProgrammerConnection = "Programmer Connection",
  MCProgrammingError = "MC Programming Error",
  DSPProgrammingError = "DSP Programming Error",
  RedCellLed = "Red Cell LED",
  RedHeartLed = "Red Heart LED",
  NoCommunication = "No Communication",
  PowerOnFailure = "Power On Failure",
  ResetLoop = "Reset Loop",
  AlarmsOnCharger = "Alarms on Charger",
  EcgSnapFailure = "ECG Snap Failure",
  CellSignalFailure = "Cell Signal Failure",
  EcgDataFailure = "ECG Data Failure",
  ConnectorFailure = "Connector Failure",
  SdCardFailure = "SD Card Failure",
  UnrepairableLeak = "Unrepairable Leak",
  EcgFailure = "ECG failure",
  Labeling = "Labeling",
  MCUnlockError = "MC Unlock Error",
  DebugInFailure = "Debug In Failure",
  DebugOutFailure = "Debug Out Failure",
  UnableToFindBoard = "Unable to find board reprogram justification",
  ModemUpdateFailure = "Modem Update Failure",
  ModemPowerOnFailure = "Modem Power On Failure",
  ModemPowerOffFailure = "Modem Power Off Failure",
  DocumentationError = "Documentation error",
  Other = "Other"
}