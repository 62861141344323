import { applySnapshot, flow, SnapshotIn, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { ReleasedDispositionState } from "../states/released-disposition.state";
import { ReleasedDeviceActionType } from "../boot/enums/ReleasedDeviceActionType";
import { TApiResponse } from "../types";
import { IReleasedDispositionResponse } from "../models/IReleasedDispositionResponse";
import { releasedDevicesApi } from "../services/api";
import { IReleasedDispositionRequest } from "../models/IReleasedDispositionRequest";

export const ReleasedDispositionModel = types
  .model("ReleasedDispositionModel", {
    show: types.optional(types.boolean, false),
    id: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    deviceDisposition: types.maybeNull(types.number),
    comment: types.maybe(InputFormModel),
    failureMode: types.maybe(InputFormModel),
    baseData: types.frozen(),
  })
  .actions(self => {
    const resetForm = () => {
      self.id = "";
      self.serialNumber = "";
      self.deviceDisposition = 0;
      self.baseData = {};
      applySnapshot(self, ReleasedDispositionState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      
      if (action === ReleasedDeviceActionType.Submit) {
        if (!self.comment?.value) {
          errors.comment = "Please fill the field";
          isValid = false;
        } else {
          if (self.comment?.value.length > 300) {
            errors.comment = "Maximum 300 characters required";
            isValid = false;
          }
        }

        if (!self.failureMode?.value || self.failureMode?.value === "0") {
          errors.failureMode = "Please select Failure Mode";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(id: string) {
      try {
        const response: TApiResponse<IReleasedDispositionResponse> = yield releasedDevicesApi.getReleasedDevice(id);
        if (response.isOk) {
          const { data } = response;
          self.id = data.id;
          self.deviceDisposition = data.deviceDisposition;
          self.serialNumber = data.serialNumber;

          self.comment.setValue(data.comment);

          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);

          self.baseData = {
            comment: data.comment,
            failureMode: data.failureMode,
            deviceDisposition: data.deviceDisposition
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number) {
      try {
        const data: IReleasedDispositionRequest = {
          deviceId: self.id,
          comment: self.comment.value,
          deviceDisposition: self.deviceDisposition,
          failureMode: +self.failureMode.value,
          action: action
        };
        const result = yield releasedDevicesApi.saveReleasedDevice(data);
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.deviceDisposition !== self.baseData.deviceDisposition
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
      ) {
        return true;
      } else {
        return false;
      }
    };


    const setDeviceDisposition = (value: number) => {
      self.deviceDisposition = value;
    };

    return {
      resetForm,
      openForm,
      closeForm,
      validateField,
      fetchData,
      saveForm,
      isFormChanged,
      setDeviceDisposition
    };
  });

export interface IReleasedDispositionModelSnapShot extends SnapshotIn<typeof ReleasedDispositionModel> {}
