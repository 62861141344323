import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useRootStore } from "../../stores/storeContext";
import debounce from "lodash.debounce";
import { Permissions } from "../../services/permission.service";
import { 
  VQButtonExport, 
  VQCategoryItem, 
  VQInputSearch, 
  VQPagination, 
  VQTableCategoriesSection, 
  VQTableSearchSection 
} from "vivaquant-components-library";
import { DecommissionedListCategories, PAGINATION } from "../../boot/constants";

export const ETDecommissionedListHeader: FC = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    page,
    total,
    nextPage,
    previousPage,
    setFirstPage,
    setActiveCategory,
    activeCategory,
    categoriesTotals,
    downloadExport
  } = useRootStore().decommissionedListStore;
  const { hasPermission } = useRootStore().userStore;
  const { setGlobalSpiner, isOpenedRightForm } = useRootStore().globalStore;

  const handleSearchChange = (value: string) => {
    setSearch(value);
    refreshData();
  };

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  const changeListCategory = (value: string, state: string) => {
    setActiveCategory(value, categoriesTotals[`${state}CategoryTotal`]);
  };

  const handleExport = () => {
    setGlobalSpiner(true);
    downloadExport().then(() => setGlobalSpiner(false));
  };

  return (
    <>
      <VQTableSearchSection>
        <VQInputSearch
          disabled={isOpenedRightForm}
          handleChange={handleSearchChange}
          placeholder={"Search by the SN and a Failure Mode"}
          value={searchValue}
          autoComplete={false}/>
        <div className="left">
          <VQButtonExport 
            click={handleExport}
            isDisabled={!hasPermission(Permissions.DevicesExport)}
          ></VQButtonExport>
          <VQPagination
            disabled={isOpenedRightForm}
            next={nextPage}
            previous={previousPage}
            total={total}
            countOnPage={PAGINATION.ROWS_PER_PAGE}
            disableControls={true}
            page={page}/>
        </div>
      </VQTableSearchSection>
      <VQTableCategoriesSection>
        {DecommissionedListCategories.map((item, index) => (
          <VQCategoryItem
            key={index}
            onChange={(value: string) => { changeListCategory(value, item.state); }}
            title={item.title}
            count={categoriesTotals[`${item.state}CategoryTotal`]}
            value={item.value}
            isActive={activeCategory === item.value}
          ></VQCategoryItem>
        ))}
      </VQTableCategoriesSection>
    </>
  );
});
