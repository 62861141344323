import { API } from "../../boot/api";
import { ApiResponseDto } from "../../models/api";
import { IDecommissionedCountResponse } from "../../models/IDecommissionedCountResponse";
import { IDecommissionedModelSnapShot } from "../../stores/decommissioned-list.store";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { BaseFilter } from "../filters/base.filter";

class DecommissionedDevicesApi {
  @ProcessAPIError()
  public async getCounters(filter: BaseFilter): Promise<TApiResponse<IDecommissionedCountResponse>> {
    const result = await API.get("/decommissioned-devices/count", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getReleasedList(filter: BaseFilter)
  : Promise<TApiResponse<IDecommissionedModelSnapShot[]>> {
    const { data } = await API.get("/decommissioned-devices",
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: BaseFilter): Promise<TApiResponse<any>> {
    const result = await API.get("/decommissioned-devices/export",
      { params: filter });
    return new ApiResponseDto(result);
  }
}

export const decommissionedDevicesApi = new DecommissionedDevicesApi();